
import { defineComponent, onMounted } from "vue";

import prodileCard from "@/components/widgets/mixed/prodileCard.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "dashboard.dashboard",
  components: {
    prodileCard,
  },
  setup() {
    const { t } = useI18n();
    onMounted(() => {
      setCurrentPageTitle(t("dashboard.dashboard"));
    });
    return {
      t,
    };
  },
});
