
import { defineComponent, onMounted, ref, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "widget-1",
  components: {},
  props: {
    widgetClasses: String,
    widgetColor: String,
    strokeColor: String,
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();

    const building = computed(() => store.getters.serverConfigUrl.building);
    return {
      t,
      store,
      building,
    };
  },
});
